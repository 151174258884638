import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart = _resolveComponent("chart")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ height: `${_ctx.height}px` })
  }, [
    _createVNode(_component_chart, {
      option: _ctx.chartOption,
      style: _normalizeStyle({ height: _ctx.chartHeight })
    }, null, 8, ["option", "style"])
  ], 4))
}