import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chartContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart = _resolveComponent("chart")!

  return (_openBlock(), _createElementBlock("div", {
    class: "miniChart",
    style: _normalizeStyle({ height: `${_ctx.height}px` })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_chart, {
        option: _ctx.chartOption,
        style: _normalizeStyle({ height: _ctx.chartHeight })
      }, null, 8, ["option", "style"])
    ])
  ], 4))
}