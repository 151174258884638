import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae9d0182"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: "waterWave",
    style: _normalizeStyle({ transform: `scale(${_ctx.radio})` })
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle({ width: `${_ctx.height}px`, height: `${_ctx.height}px`, overflow: 'hidden' })
    }, [
      _createElementVNode("canvas", {
        ref: "node",
        class: "waterWaveCanvasWrapper",
        width: _ctx.height * 2,
        height: _ctx.height * 2
      }, null, 8, _hoisted_1)
    ], 4),
    _createElementVNode("div", {
      class: "text",
      style: _normalizeStyle({ width: `${_ctx.height}px` })
    }, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("h4", null, _toDisplayString(_ctx.percent) + "%", 1)
    ], 4)
  ], 4))
}